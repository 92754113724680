//import * as serviceWorker from '@yourfuse/yf-shell/lib/utils/serviceWorker'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'
//import 'default-passive-events'
import React from 'react'
import { createRoot } from 'react-dom/client'
import Loadable from 'react-loadable'
import LoadingComponent from '@yourfuse/yf-shell/lib/components/LoadingComponent'
//import App from './App'
//require('dotenv').config()
const Loading = () => <LoadingComponent />
export const AppAsync = Loadable({
  loader: () => import('./App'),
  loading: Loading
})

const container = document.getElementById('root')
const root = createRoot(container)

if (process.env.NODE_ENV === 'development') {
  console.log('DEV MODE')
}

root.render(<React.Fragment><AppAsync /></React.Fragment>)

if ('serviceWorker' in navigator) {
  //serviceWorker.register({})
  serviceWorkerRegistration.register({
    onUpdate: (reg) => {
      console.log('Service worker onUpdate callback triggered.');
      try {
        console.log('Service worker state:', reg.waiting ? reg.waiting.state : 'No waiting service worker');
        window.update = () => {
          try {
            if (reg.waiting) {
              reg.waiting.postMessage({ type: 'SKIP_WAITING' });
              reg.waiting.addEventListener('statechange', event => {
                if (event.target.state === 'activated') {
                  window.location.reload();
                }
              });
            } else {
              console.warn('Service worker not in waiting state.');
            }
          } catch (error) {
            console.warn('error', error);
          }
        }
      } catch (error) {
        console.warn('error', error);
      }


    },
  })
}

function clearServiceWorkerCache() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.active.postMessage({ type: 'CLEAR_CACHE' })
    })
  }
}

window.clearServiceWorkerCache = clearServiceWorkerCache
